import React, { Component } from 'react';
import Layout from '../components/layout';
import '../styles/global.css';

//NPM
import Gallery from 'react-grid-gallery';
import Loader from 'react-loader-spinner';
import axios from 'axios';

//Components
import SectionIntro from '../components/section-intro';
import SectionTitle from '../components/section-title';
import ResponsiveFrame from '../components/responsive-frame';
import { Link } from 'gatsby';
import Button from '../components/button';
import SEO from '../components/seo';

const baseUrl = 'https://murmuring-anchorage-50029.herokuapp.com/';
const tag = 'HRS';
const picTag = 'PICNIC';

class YupAdventuresPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pictures: [],
			gallery: [],
			picnicPics: [],
			picGallery: [],
			loading: false
		};
	}

	componentDidMount() {
		this.loadPicnic();

		this.setState({ loading: true });

		//Generate the URL for the required album
		let tagUrl = 'images/' + tag;

		//Request from custom API
		axios
			.post(baseUrl + tagUrl, {
				tag: tag
			})
			.then(res => {
				console.log('response recieveddddd');
				this.setState({ pictures: res.data.resources });
				this.setState({ gallery: [] });

				this.gallerySetup();
				this.setState({ loading: false });
			})
			.catch(err => {
				console.log(err);
				this.setState({ loading: false });
			});
	}

	loadPicnic() {
		//Generate the URL for the required album
		let tagUrl = 'images/' + picTag;

		//Request from custom API
		axios
			.post(baseUrl + tagUrl, {
				tag: picTag
			})
			.then(res => {
				console.log('response recieveddddd');
				this.setState({ picnicPics: res.data.resources });
				this.setState({ picGallery: [] });

				this.picGallerySetup();
				this.setState({ loading: false });
			})
			.catch(err => {
				console.log(err);
				this.setState({ loading: false });
			});
	}

	picGallerySetup() {
		this.state.picnicPics.map(photo => {
			let transform = '/w_0.2,h_0.2,q_auto:eco,f_auto';
			let transformUrl = [
				photo.secure_url.slice(0, 49),
				transform,
				photo.secure_url.slice(49)
			].join('');
			// this.setState({ gallery: [...this.state.gallery, { src: photo.secure_url, width: photo.width, height: photo.height }] })
			this.setState({
				picGallery: [
					...this.state.picGallery,
					{
						src: transformUrl,
						thumbnail: transformUrl,
						thumbnailWidth: photo.width,
						thumbnailHeight: photo.height
					}
				]
			});
		});
	}

	gallerySetup() {
		this.state.pictures.map(photo => {
			let transform = '/q_auto:eco,f_auto';
			let transformUrl = [
				photo.secure_url.slice(0, 49),
				transform,
				photo.secure_url.slice(49)
			].join('');
			// this.setState({ gallery: [...this.state.gallery, { src: photo.secure_url, width: photo.width, height: photo.height }] })
			this.setState({
				gallery: [
					...this.state.gallery,
					{
						src: transformUrl,
						thumbnail: transformUrl,
						thumbnailWidth: photo.width,
						thumbnailHeight: photo.height
					}
				]
			});
		});
	}

	render() {
		return (
			<Layout>
				<SEO
					title="YUP Adventures"
					keywords={[
						`yup`,
						`life`,
						`FOC`,
						`Festival`,
						`Colour`,
						`Backyard`,
						`Melee`,
						`Events`,
						`Fete`,
						`Trinidad`,
						`Carnival`,
						`Party`,
						`Fete`
					]}
				/>

				<SectionIntro
					title="Yup Adventures"
					copy="Up for more than just a party? Come get a taste of the outdoor life with YupLife Adventures. Explore our previous adventures below."
				/>
				<SectionTitle
					title="Picnic Wars 2018"
					subtitle="We decided to see who is the best chef by dividing our entire team into smaller teams and having them compete."
				/>

				<ResponsiveFrame src="https://www.youtube.com/embed/Lk_IUTgdY6s" />
				<div className="mediaGallery">
					<Gallery
						images={this.state.picGallery}
						enableImageSelection={false}
						margin={1}
						backdropClosesModal={true}
					/>
				</div>

				<SectionTitle
					title="Hike to Rio Seco Waterfalls"
					subtitle="One of Trinidad’s most stunning waterfalls located on the North Eastern Coast"
				/>
				{this.state.loading ? (
					<div className="center">
						<Loader
							type="ThreeDots"
							color="#B4271D"
							height="50"
							width="50"
						/>
					</div>
				) : null}
				<div className="mediaGallery">
					<Gallery
						images={this.state.gallery}
						enableImageSelection={false}
						margin={1}
						backdropClosesModal={true}
					/>
				</div>

				<div className="space"></div>

				<Link to="/join">
					<Button title="Join the Team" />
				</Link>
			</Layout>
		);
	}
}

export default YupAdventuresPage;
